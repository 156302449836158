<template>
  <div class="SearchOffreCession" >
    <div class="container">
      <RegisterToNewsletterButton 
        @clickOnButton="showCreateNewsletterSubscriber = true"
      />
      <b-modal size="lg" v-model="showCreateNewsletterSubscriber" hide-footer title="Créer une alerte">
        <CreateNewsletterSubscriber
          @registered="hasRegisteredNewsletterSubscriber" 
        />
      </b-modal>

      <h1 class="gavc-h1 text-center mb-4">Rechercher une offre de cession</h1>

      <p class="gavc-chapo">
        {{nbAnnoncesTotal}}
        <span v-if="nbAnnoncesTotal > 1">annonces</span>
        <span v-else>annonce</span>
      </p>

      <b-row
        align-h="between"
        align-v="center"
      >
        <b-col>
          <strong class="gavc-chapo">
            Filtrer les annonces
          </strong>
        </b-col>
        <b-col class="text-right">
          <button
            @click="reinitializeFilters"
            class="gavc-btn gavc-btn--bone pl-4 pr-4">
            Réinitialiser
          </button>
        </b-col>
      </b-row>

      <div
        class="gavc-field mt-2"
        v-bind:class="{ 'is-error': filterKeywords && $v.filterKeywords.$dirty ? $v.filterKeywords.$error : null  }">
        <label class="gavc-label" for="filter-annonces-filterKeywords-group">
          Mots-clés dans l'annonce
        </label>
        <input
          class="gavc-input"
          id="filter-annonces-filterKeywords-group"
          maxlength="100"
          placeholder="Société à vendre"
          @input="$v.filterKeywords.$touch()"
          type="text"
          v-model="filterKeywords"
        >
      </div>


      <div class="row mt-2">
        <div class="col-12 col-md-4 mt-2">
          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': filterPriceMin && $v.filterPriceMin.$dirty ? $v.filterPriceMin.$error : null  }">
            <label class="gavc-label" for="filter-annonces-filterPriceMin-input">
              Prix minimum
            </label>
            <div class="gavc-field gavc-field--icon">
              <input class="gavc-input"
                    @input="$v.filterPriceMin.$touch()"
                    id="filter-annonces-filterPriceMin-input"
                    placeholder="00.00"
                    type="number"
                    v-model="filterPriceMin">
              <i class="gavc-icon gavc-icon-euro" ></i>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': filterPriceMax && $v.filterPriceMax.$dirty ? $v.filterPriceMax.$error : null  }">
            <label class="gavc-label" for="filter-annonces-filterPriceMax-input">
              Prix maximum
            </label>
            <div class="gavc-field gavc-field--icon">
              <input class="gavc-input"
                    @input="$v.filterPriceMax.$touch()"
                    id="filter-annonces-filterPriceMax-input"
                    placeholder="00.00"
                    type="number"
                    v-model="filterPriceMax">
              <i class="gavc-icon gavc-icon-euro" ></i>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div
            class="gavc-field"
          >
            <label class="gavc-label" for="filter-annonces-filterDepartements-input">
              Département
            </label>
            <vue-multiselect-input
              id="searchDepartementsInput"
              name="search-departement-input"
              v-model="filterDepartements"
              :options="filterDepartementsOptions"
              :multiple="true"
              :group-select="true"
              placeholder="01, 91, ..."
              selectLabel="Ajouter"
              deselectLabel="Supprimer"
              selectedLabel="Sélectionné"
              :ref="`multiselect-departements`"
              track-by="value"
              label="text">
              <span slot="noResult">Aucun élément trouvé, veuillez réessayer</span>
              <span slot="noOptions">Aucun élément</span>
            </vue-multiselect-input>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-right">
          <button
            @click="filterOffresCession"
            :disabled="invalidFilters"
            class="gavc-btn mt-2"
            type="submit">
              Filtrer
          </button>
        </div>
      </div>

      <div class="row mt-4" v-for="annonce in annonces" :key="annonce.id">
        <div class="col-12">
          <div
            @click="$router.push(`/offres-de-cession/public/${annonce.id}`)"
            style="cursor: pointer;">
            <annonceItem
              :annonce="annonce"
              :withBaseApiUrl="true"
            />
          </div>
        </div>
      </div>

      <div v-if="getAllAnnoncesUrl" class="row  mt-2">
        <div class="col-12 text-center">
          <button
            class="gavc-btn gavc-btn--bone"
            @click='getAllOffresCession()'>
              Voir plus
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { minValue, maxValue, minLength, maxLength, numeric} from 'vuelidate/lib/validators'

import { APIDisconnectedService } from '@/api/APIDisconnected'
import { apiBaseUrl } from '@/variables/localVariables'
import { departementsList } from '@/variables/departements'

const apiDisconnectedService = new APIDisconnectedService()
const annonceItem = () => import('@/components/annonce/annonceItem')
const CreateNewsletterSubscriber = () => import('@/components/newsletter/CreateNewsletterSubscriber')
const RegisterToNewsletterButton = () => import('@/components/newsletter/RegisterToNewsletterButton')

export default {
  name: 'SearchOffreCession',
  components: {
    annonceItem,
    CreateNewsletterSubscriber,
    RegisterToNewsletterButton
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      baseGetAllAnnoncesUrl: apiBaseUrl + `/get-all-annonces`,
      getAllAnnoncesUrl: apiBaseUrl + `/get-all-annonces`,
      nbAnnoncesTotal: "",

      filterDepartementsOptions: departementsList,
      filterDepartements: [],
      filterKeywords: '',
      filterPriceMax: '',
      filterPriceMin: '',
      annonces: [],

      showCreateNewsletterSubscriber: false,
    }
  },
  computed: {
    departementsValues () {
      return this.filterDepartements.map((dep) => dep.value).join(',')
    },
    invalidFilters () {
      if (this.$v.$invalid) {
        return true
      }
      return false
    }
  },
  mounted: function() {
    if ('keywords' in this.$route.query) {
      this.filterKeywords = this.$route.query.keywords
    }
    if ('departements' in this.$route.query) {
      const departementsValues = this.$route.query.departements.split(',')
      this.filterDepartements = this.filterDepartementsOptions.filter((item) => departementsValues.includes(item.value))
    }
    if ('priceMax' in this.$route.query) {
      this.filterPriceMax = this.$route.query.priceMax
    }
    if ('priceMin' in this.$route.query) {
      this.filterPriceMin = this.$route.query.priceMin
    }
    this.getAllOffresCession()
  },
  validations: {
    filterKeywords: {
      minLength: minLength(2),
      maxLength: maxLength(255)
    },
    filterPriceMax: {
      numeric,
      minValue: minValue(1),
      maxValue: maxValue(100000000)
    },
    filterPriceMin: {
      numeric,
      minValue: minValue(1),
      maxValue: maxValue(100000000)
    }
  },
  methods: {
    filterOffresCession () {
      console.log('filter')
      console.log(this.filterKeywords)
      this.annonces = []
      this.getAllAnnoncesUrl = this.baseGetAllAnnoncesUrl
      const data  = {}
      if (this.departementsValues) {
        data['departements'] = this.departementsValues
      }
      if (this.filterKeywords) {
        data['keywords'] = this.filterKeywords
      }
      if (this.filterPriceMax) {
        data['priceMax'] = this.filterPriceMax
      }
      if (this.filterPriceMin) {
        data['priceMin'] = this.filterPriceMin
      } 
      this.$router.push({path: "/offres-de-cession/recherche", query: data})
      this.getAllOffresCession()
    },
    getAllOffresCession () {
      this.$store.commit('loadFromAPI')
      var params = {}
      if (this.departementsValues) {
        params['departements'] = this.departementsValues
      }
      if (this.filterKeywords) {
        params['keywords'] = this.filterKeywords
      }
      if (this.filterPriceMax) {
        params['priceMax'] = this.filterPriceMax
      }
      if (this.filterPriceMin) {
        params['priceMin'] = this.filterPriceMin
      }
      apiDisconnectedService.getAllAnnonces(this.getAllAnnoncesUrl, params)
      .then((result) => {
        this.annonces = this.annonces.concat(result.data.results)
        this.getAllAnnoncesUrl = result.data.next
        this.nbAnnoncesTotal = result.data.count
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('SearchOffreCession getAllAnnonces API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    hasRegisteredNewsletterSubscriber () {
      this.showCreateNewsletterSubscriber = false
      this.$bvToast.toast("Vous êtes bien inscrit à notre module d'alerte et recevrez des propositions d'entreprises à reprendre qui répondent à vos critères.", {
        title: 'inscription validée',
        variant: 'success',
        solid: true
      })
    },

    reinitializeFilters () {
      this.annonces = []
      this.filterDepartements = []
      this.filterKeywords = null
      this.filterPriceMax = null
      this.filterPriceMin = null
      this.getAllAnnoncesUrl = this.baseGetAllAnnoncesUrl
      const data  = {}
      this.$router.push({path: "/offres-de-cession/recherche", query: data})
      this.getAllOffresCession()
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';


.multiselect__tags {
  background-color: $white-darken-1 !important;
  border: none !important;
  min-height: 55px !important;
  padding-top: 12px !important;
}
.multiselect__input {
  background-color: $white-darken-1 !important;
}
.multiselect__content-wrapper {
  background-color: $white-darken-1 !important;
}
.multiselect__option--highlight {
  background-color: $green-darken-1 !important;
}
.multiselect__option--highlight::after {
  background-color: $green-darken-1 !important;
}
.multiselect__option--selected {
 background-color: $white-darken-1 !important; 
}

.multiselect__tag {
  background-color: $green-darken-1 !important;
}

.multiselect__tag-icon:after {
    color: white;
}

.multiselect__tag-icon:hover {
  background-color: $green-darken-1 !important;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: $green-darken-1 !important;
}


</style>