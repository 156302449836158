export const departementsList = [
    {
        "value": "01",
        "text": "01 - Ain",
    },
    {
        "value": "02",
        "text": "02 - Aisne",
    },
    {
        "value": "03",
        "text": "03 - Allier",
    },
    {
        "value": "04",
        "text": "04 - Alpes-de-Haute-Provence",
    },
    {
        "value": "05",
        "text": "05 - Hautes-Alpes",
    },
    {
        "value": "06",
        "text": "06 - Alpes-Maritimes",
    },
    {
        "value": "07",
        "text": "07 - Ardèche",
    },
    {
        "value": "08",
        "text": "08 - Ardennes",
    },
    {
        "value": "09",
        "text": "09 - Ariège",
    },
    {
        "value": "10",
        "text": "10 - Aube",
    },
    {
        "value": "11",
        "text": "11 - Aude",
    },
    {
        "value": "12",
        "text": "12 - Aveyron",
    },
    {
        "value": "13",
        "text": "13 - Bouches-du-Rhône",
    },
    {
        "value": "14",
        "text": "14 - Calvados",
    },
    {
        "value": "15",
        "text": "15 - Cantal",
    },
    {
        "value": "16",
        "text": "16 - Charente",
    },
    {
        "value": "17",
        "text": "17 - Charente-Maritime",
    },
    {
        "value": "18",
        "text": "18 - Cher",
    },
    {
        "value": "19",
        "text": "19 - Corrèze",
    },
    {
        "value": "21",
        "text": "21 - Côte-d'Or",
    },
    {
        "value": "22",
        "text": "22 - Côtes-d'Armor",
    },
    {
        "value": "23",
        "text": "23 - Creuse",
    },
    {
        "value": "24",
        "text": "24 - Dordogne",
    },
    {
        "value": "25",
        "text": "25 - Doubs",
    },
    {
        "value": "26",
        "text": "26 - Drôme",
    },
    {
        "value": "27",
        "text": "27 - Eure",
    },
    {
        "value": "28",
        "text": "28 - Eure-et-Loir",
    },
    {
        "value": "29",
        "text": "29 - Finistère",
    },
    {
        "value": "30",
        "text": "30 - Gard",
    },
    {
        "value": "31",
        "text": "31 - Haute-Garonne",
    },
    {
        "value": "32",
        "text": "32 - Gers",
    },
    {
        "value": "33",
        "text": "33 - Gironde",
    },
    {
        "value": "34",
        "text": "34 - Hérault",
    },
    {
        "value": "35",
        "text": "35 - Ille-et-Vilaine",
    },
    {
        "value": "36",
        "text": "36 - Indre",
    },
    {
        "value": "37",
        "text": "37 - Indre-et-Loire",
    },
    {
        "value": "38",
        "text": "38 - Isère",
    },
    {
        "value": "39",
        "text": "39 - Jura",
    },
    {
        "value": "40",
        "text": "40 - Landes",
    },
    {
        "value": "41",
        "text": "41 - Loir-et-Cher",
    },
    {
        "value": "42",
        "text": "42 - Loire",
    },
    {
        "value": "43",
        "text": "43 - Haute-Loire",
    },
    {
        "value": "44",
        "text": "44 - Loire-Atlantique",
    },
    {
        "value": "45",
        "text": "45 - Loiret",
    },
    {
        "value": "46",
        "text": "46 - Lot",
    },
    {
        "value": "47",
        "text": "47 - Lot-et-Garonne",
    },
    {
        "value": "48",
        "text": "48 - Lozère",
    },
    {
        "value": "49",
        "text": "49 - Maine-et-Loire",
    },
    {
        "value": "50",
        "text": "50 - Manche",
    },
    {
        "value": "51",
        "text": "51 - Marne",
    },
    {
        "value": "52",
        "text": "52 - Haute-Marne",
    },
    {
        "value": "53",
        "text": "53 - Mayenne",
    },
    {
        "value": "54",
        "text": "54 - Meurthe-et-Moselle",
    },
    {
        "value": "55",
        "text": "55 - Meuse",
    },
    {
        "value": "56",
        "text": "56 - Morbihan",
    },
    {
        "value": "57",
        "text": "57 - Moselle",
    },
    {
        "value": "58",
        "text": "58 - Nièvre",
    },
    {
        "value": "59",
        "text": "59 - Nord",
    },
    {
        "value": "60",
        "text": "60 - Oise",
    },
    {
        "value": "61",
        "text": "61 - Orne",
    },
    {
        "value": "62",
        "text": "62 - Pas-de-Calais",
    },
    {
        "value": "63",
        "text": "63 - Puy-de-Dôme",
    },
    {
        "value": "64",
        "text": "64 - Pyrénées-Atlantiques",
    },
    {
        "value": "65",
        "text": "65 - Hautes-Pyrénées",
    },
    {
        "value": "66",
        "text": "66 - Pyrénées-Orientales",
    },
    {
        "value": "67",
        "text": "67 - Bas-Rhin",
    },
    {
        "value": "68",
        "text": "68 - Haut-Rhin",
    },
    {
        "value": "69",
        "text": "69 - Rhône",
    },
    {
        "value": "70",
        "text": "70 - Haute-Saône",
    },
    {
        "value": "71",
        "text": "71 - Saône-et-Loire",
    },
    {
        "value": "72",
        "text": "72 - Sarthe",
    },
    {
        "value": "73",
        "text": "73 - Savoie",
    },
    {
        "value": "74",
        "text": "74 - Haute-Savoie",
    },
    {
        "value": "75",
        "text": "75 - Paris",
    },
    {
        "value": "76",
        "text": "76 - Seine-Maritime",
    },
    {
        "value": "77",
        "text": "77 - Seine-et-Marne",
    },
    {
        "value": "78",
        "text": "78 - Yvelines",
    },
    {
        "value": "79",
        "text": "79 - Deux-Sèvres",
    },
    {
        "value": "80",
        "text": "80 - Somme",
    },
    {
        "value": "81",
        "text": "81 - Tarn",
    },
    {
        "value": "82",
        "text": "82 - Tarn-et-Garonne",
    },
    {
        "value": "83",
        "text": "83 - Var",
    },
    {
        "value": "84",
        "text": "84 - Vaucluse",
    },
    {
        "value": "85",
        "text": "85 - Vendée",
    },
    {
        "value": "86",
        "text": "86 - Vienne",
    },
    {
        "value": "87",
        "text": "87 - Haute-Vienne",
    },
    {
        "value": "88",
        "text": "88 - Vosges",
    },
    {
        "value": "89",
        "text": "89 - Yonne",
    },
    {
        "value": "90",
        "text": "90 - Territoire de Belfort",
    },
    {
        "value": "91",
        "text": "91 - Essonne",
    },
    {
        "value": "92",
        "text": "92 - Hauts-de-Seine",
    },
    {
        "value": "93",
        "text": "93 - Seine-Saint-Denis",
    },
    {
        "value": "94",
        "text": "94 - Val-de-Marne",
    },
    {
        "value": "95",
        "text": "95 - Val-d'Oise",
    },
    {
        "value": "2A",
        "text": "2A - Corse-du-Sud",
    },
    {
        "value": "2B",
        "text": "2B - Haute-Corse",
    }
]